<template>
  <div class="bindphone" v-show="showPage">
    <p>
      You need to associate an e-mail
    </p>

    <Form class="F" ref="formInline1" :model="formInline1" :rules="ruleInline1" :label-width="80" @keyup.enter.native="handleSubmit1('formInline1')">
      <FormItem class="F-1 phone_code" prop="email" label="E-mail：">
        <Input class="I" type="text" v-model="formInline1.email" placeholder="E-mail" />
      </FormItem>
      <FormItem class="F-1 phone_code" prop="code" label="Code：">
        <Input class="I" type="text" ref="captcha_input2" maxlength="6" v-model="formInline1.code" placeholder="Code" autocomplete="off" />
        <Button v-if="showCode" @click="getPhoneCode">Send</Button>
        <Button v-else disabled style="color: #ccc">{{ count }}s</Button>
      </FormItem>
      <FormItem style="margin-top: 40px">
        <Button type="primary" @click="handleSubmit1('formInline1')" size="large" :loading="loading" style="width: 100%">Submit</Button>
      </FormItem>
    </Form>
    <Modal v-model="showTip" title="Have a problem" @on-ok="tipCancel" @on-cancel="tipOk" ok-text="Change Email" cancel-text="Skip">
      <p style="color: red; margin-bottom: 10px">Have a problem</p>
      <p>Email（{{ formInline1.email }}）has been bound to other accounts</p>
      <div style="text-align: right">
        <a href="javascript:;" @click="loginNow">Sign in</a>
      </div>
    </Modal>
  </div>
</template>

<script>
import { validator } from "@/other/validator";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "auth-bindphone",
  data() {
    return {
      showPage: false,
      showTip: false,
      register: false, // 未注册成功
      clear: true, // 没有绑定手机退出 清楚手机号和登录相关的状态
      show: false,
      loading: false,
      showCode: true,
      timer: null,
      count: "",
      codeKey: "",
      formInline1: {
        email: "",
        code: "",
      },
      ruleInline1: {
        email: [
          { required: true, message: "Please add an e-mail", trigger: "blur" },
          { type: "email", message: "The mailbox format is incorrect", trigger: "blur" },
        ],
        code: [{ required: true, message: "Please add the code", trigger: "blur" }],
      },
    };
  },
  created() {
    if (this.user && this.user.email) {
      this.setLogin(true);
      this.clear = false;
      this.$router.push(`/home/index/${this.exhibitionId}`);
      return;
    }
    this.showPage = true;
    this.setLogin(false);
  },
  destroyed() {
    if (this.clear) {
      this.setLogin(false);
      this.setUser("");
      this.setUserId("");
      this.signOutOidc();
      this.signOutOidcQQ();
      this.signOutOidcLinkedIn();
      this.setRedirectUrl(login.href + "/login/" + this.exhibitionId);
    }
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      token: "getToken",
      user: "getUser",
      loginType: "getLoginType",
    }),
  },
  methods: {
    ...mapMutations({
      setLoginType: "setLoginType",
      setUser: "setUser",
      setUserId: "setUserId",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
      setExhibitionId: "setExhibitionId",
      setRedirectUrl: "setRedirectUrl",
    }),
    ...mapActions({
      signOutOidc: "signOutOidc",
      signOutOidcQQ: "oidcStoreQQ/signOutOidc",
      signOutOidcLinkedIn: "oidcStoreLinkedIn/signOutOidc",
      graphqlPost: "graphqlPost",
      getImgCode: "loginModule/getImgCode",
      validateCode: "loginModule/validateCode",
      validatePhone: "loginModule/validatePhone",
      updatePhonenumber: "user/updatePhonenumber",
      sendEamilCode: "en/sendEamilCode",
      validateEmail: "loginModule/validateEmail",
      putEmail: "en/putEmail",
    }),
    async handleSubmit1(name) {
      let valid = await this.validForm(name);
      if (!valid) return;
      // if (!this.codeKey) {
      //   this.$Message.warning("请获取验证码!");
      //   return;
      // }
      let codeValid = await this.validPhoneCode();
      if (codeValid == "codeNull") {
        if (!this.codeKey) {
          this.$Message.warning("The code is incorrect. Please try again!");
          return;
        }
      }
      if (!codeValid) return;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);

      const req = {
        memberId: this.user.inMember.memberId,
        email: this.formInline1.email,
      };

      let bool = await this.putEmail(req).catch((err) => {
        this.loading = false;
      });

      if (bool.result) {
        this.register = true;
        this.clear = false; // 绑定成功 不需要清楚登录信息
        this.$Message.success("success！");
        this.getUser();
      } else {
        if (bool.code === 0) {
          this.showTip = true;
        } else {
          this.$Message.error(bool.message);
        }
        this.formInline1.code = "";
        this.$refs.captcha_input2.focus();
      }
    },
    async getPhoneCode() {
      this.$refs.formInline1.validateField("email", async (val) => {
        if (val !== "") return;
        let res = await this.validateEmail(this.formInline1.email);
        console.log(res, "ress邮箱");
        if (res && res.data) {
          // this.$Message.error("该手机已绑定其他账号");
          this.showTip = true;
        } else {
          this.formInline1.code = "";
          this.$refs.captcha_input2.focus();
          this.downTime();
        }
      });
    },
    async downTime() {
      const valid = await this.sendEamilCode({ email: this.formInline1.email });
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async validPhoneCode() {
      // 验证code
      console.log(this.codeKey, "codeKey", this.formInline1.code);
      if (!this.codeKey || !this.formInline1.code) return "codeNull";
      //原手机认证->下一步 校验验证码，返回之后打开更换手机号弹框
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline1.code,
      });
      if (!valid) {
        this.$Message.error("The code is incorrect. Please try again！");
        this.formInline1.code = "";
        this.$refs.captcha_input2.focus();
        // this.getCode();
        return false;
      }
      return true;
    },
    async getCode() {
      // 获取code
      this.code = await this.getImgCode();
    },
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                isBlacklist
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nickName
              phone
              phoneText
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
        this.$router.push(`/home/index/${this.exhibitionId}`);
      }
    },
    tipOk() {
      this.setLogin(true);
      this.clear = false;
      this.$router.push(`/home/index/${this.exhibitionId}`);
    },
    tipCancel() {
      this.formInline1.code = "";
      this.formInline1.email = "";
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
      this.count = "";
      this.codeKey = "";
      this.$refs["formInline1"].resetFields();
    },
    loginNow() {
      this.setRedirectUrl("");
      location.href = location.origin + "/login/" + this.exhibitionId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.bindphone {
  width: 500px;
  margin: 200px auto 0;
  p {
    margin-bottom: 30px;
    padding-left: 140px;
  }
}
.F {
  display: flex;
  flex-direction: column;
  width: 400px;
  position: relative;
  margin: 0 auto;
  &-1 {
    margin-top: 34px;
    margin-bottom: 0;
    &.phone_code {
      ::v-deep {
        .ivu-form-item-content {
          display: flex;
          .ivu-btn {
            margin-left: 10px;
            width: 120px;
            flex-shrink: 0;
            @include font_color(#1890ff);
          }
        }
      }
    }
    .I {
      // margin-top: 19px;
    }
  }
  .F-2 {
    width: 100%;
    height: 15px;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .F-2-text {
      width: 48px;
      height: 13px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }
  }
  .wx-btn {
    margin-top: 20px;
  }
  .code {
    width: 70px;
    height: 26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    cursor: pointer;
  }
}
.icon-xinxi {
  font-size: 30px;
  cursor: pointer;
  &:hover {
    @include font_color(#1890ff);
  }
}
.c-m {
  margin-top: 30px;
  height: 600px;
  &-one {
    background-color: #fafafa;
    border: 1px solid rgba(221, 221, 221, 0.3);
    border-radius: 5px;
    margin-bottom: 15px;
    height: 100px;
    display: flex;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid #e6e6e6;
      margin-left: 20px;
      margin-top: 15px;
    }
    .one-r {
      width: calc(100% - 80px);
      height: 100%;
      padding-left: 20px;
      padding-top: 15px;
      padding-right: 10px;
      h2 {
        font-size: 16px;
        color: #262626;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        color: #8c8c8c;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.c-b {
  text-align: center;
}
.select-input {
  .icon-filter {
    position: absolute;
    right: 2px;
    top: 1px;
    cursor: pointer;
    font-size: 26px;
    color: #ccc;
  }
  .ex-list {
    position: absolute;
    background-color: #fff;
    z-index: 900;
    top: 35px;
    left: 0;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    border-radius: 4px;
    width: 100%;
    p {
      width: 100%;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #333;
      &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
      }
    }
  }
}
.other {
  width: 100%;
  background: #fff;
  .title {
    font-size: 14px;
    color: #666;
    text-align: center;
    position: relative;
    margin: 19px 0 15px 0;
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 100px;
      height: 1px;
      background: #e6e6e6;
      top: 50%;
      margin-top: -1px;
    }
  }
  &-content {
    display: flex;
    align-items: center;
    .item {
      font-size: 14px;
      color: #666;
      margin-right: 10px;
      cursor: pointer;
      padding-right: 10px;

      display: flex;
      align-items: center;
      position: relative;
      &.s-1 {
        .iconfont {
          color: #49bd38;
        }
      }
      &.s-2 {
        .iconfont {
          color: #17abe3;
        }
      }
      .iconfont {
        font-size: 20px;
        display: inline-block;
        margin-right: 9px;
      }
      &:first-child {
        &::before {
          content: "";
          right: 0;
          top: 50%;
          margin-top: -10px;
          position: absolute;
          height: 20px;
          width: 1px;
          background: #e6e6e6;
        }
      }
    }
    .item-right {
      font-size: 14px;
      color: #ff5e00;
      flex: 1;
      text-align: right;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
